<template>
  <v-container class="fill-height fullscreen-bg" fluid> 
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card class="elevation-12 pa-4">
          <v-card-title class="headline">{{$t('account.change-password.title')}}</v-card-title>
          <v-card-text>
            <p>{{$t('account.change-password.intro')}}</p>
            <v-alert v-if="error" type="error">{{error}}</v-alert>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="email"
                :label="$t('account.change-password.email')"
                readonly
                disabled
                prepend-icon="fa-user"
                />
              <v-text-field
                v-model="currentPassword"
                :rules="currentPasswordRules"
                :label="$t('account.change-password.current-password')"
                required
                prepend-icon="fa-lock"
                :append-icon="passwordShow ? 'fal fa-eye-slash' : 'fal fa-eye'"
                :type="passwordShow ? 'text' : 'password'"
                @click:append="passwordShow = !passwordShow"
                />

              <v-text-field
                v-model="newPassword"
                :rules="newPasswordRules"
                :label="$t('account.change-password.new-password')"
                required
                prepend-icon="fa-lock"
                :append-icon="passwordShow ? 'fal fa-eye-slash' : 'fal fa-eye'"
                :type="passwordShow ? 'text' : 'password'"
                @click:append="passwordShow = !passwordShow"
                />

              <v-text-field
                v-model="newPasswordRepeat"
                :rules="newPasswordRepeatRules"
                :label="$t('account.change-password.new-password-repeat')"
                required
                prepend-icon="fa-lock"
                :append-icon="passwordShow ? 'fal fa-eye-slash' : 'fal fa-eye'"
                :type="passwordShow ? 'text' : 'password'"
                @click:append="passwordShow = !passwordShow"
                />

            </v-form>
          </v-card-text>
          <v-card-text>
            <v-spacer />
            <v-btn block :disabled="!valid" color="primary" @click="validate">{{$t('account.change-password.submit')}}</v-btn>
          </v-card-text>
          <v-divider class="mt-8"/>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getAuth } from "firebase/auth";
import { reauthenticateWithCredential, updatePassword, EmailAuthProvider  } from "firebase/auth";
import { EventBus } from '@/plugins/eventbus.js';
import profileService from "@/services/profileService";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  data() {
    return {
      passwordShow: false,
      valid: true,
      error: null,
      email: null,
      currentPassword: false,
      newPassword: "",
      newPasswordRepeat: "",
      currentPasswordRules: [
        v => !!v || "Please enter your current password",
      ],
      newPasswordRules: [
        v => !!v || "Please enter your new password",
      ],
      newPasswordRepeatRules: [
        v => !!v || "Please repeat your new password",
        v => { return this.newPassword == this.newPasswordRepeat || "Please make sure you enter the exact same password again."}
      ],
    }
  },

  async mounted() {
    const auth = getAuth();
    const user = auth.currentUser;
    this.loadUser(user);
    
    EventBus.$on('login-state-change', async user => {
      this.loadUser(user);
    });
  },

  methods: {
    async loadUser(user) {
      if (user) {
        this.email = user.email;

        if (user.providerId != "firebase" || user.isAnonymous) {
          this.error = "You cannot change the password for this account as it has not been created with a username and password."

        }
      }
    },
    async validate() {
      if (this.$refs.form.validate()) {
        this.changePasswordWithFirebase();
      }
    },

    reset() {
      this.$refs.form.reset();
    },

    async changePasswordWithFirebase() {
      const auth = getAuth();
      var user = auth.currentUser;

      try {
        const credential = EmailAuthProvider.credential(
          user.email,
          this.currentPassword
        );
        await reauthenticateWithCredential(user, credential);
        user = auth.currentUser;
      }
      catch (ex) {
        this.error = "We can't validate your current password. Please ensure you have enter the correct email and current password..";
        return;
      }

      try {
        await updatePassword(user, this.newPassword);
        this.$helpers.toastResponse(this, { status: "OK"}, "Your password has been updated successfully.");
        this.$router.push( { name: "profileEdit" });
        return;
      } catch (ex) {
        console.error(ex);
        this.error = "We couldn't change your password. Please make sure you enter a valid new password.";
      }
    }
  },
  computed: {
  },
};
</script>
<style lang="scss" scoped>
  .fullscreen-bg {
    margin-top: 60px;
    padding-top: 40px;
    background-image: url(https://sodisp.imgix.net/hero-cycling-1.jpg?w=2000&h=2000&fit=crop);
    background-size: cover;
    background-position: 50%;

    .row { margin: 0!important; }
  }
  .view--embedded .fullscreen-bg { margin-top: 0;}
</style>

